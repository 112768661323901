.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: center;
	align-items: center;
	gap: 300;
}
.contentWrapper {
	height: 15em;
	gap: 2em;
	align-items: center;
	justify-content: center;
	display: flex;
}

.icon {
	object-fit: contain;
	height: 100%;
	border-radius: 20%;
	border-width: 3px;
	border-style: solid;
}

.titlesWrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 2em;
}

.textWrapper {
	display: "flex";
	flex-direction: column;
	min-height: 0;
	gap: 5em;
}

.buttonsWrapper {
	display: flex;
	align-items: center;
	gap: 2em;
}

@media (max-width: 601px) {
	.contentWrapper {
		flex-direction: column;
	}
	.titlesWrapper {
		align-items: center;
		gap: 5em;
	}
	.textWrapper {
		align-items: center;
		justify-content: center;
		text-align: center;
		max-width: 70vw;
	}

	.buttonsWrapper {
		flex-direction: column;
	}
}

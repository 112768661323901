.App {
	text-align: left;
	max-width: 1200px;
	display: flex;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	display: flex;
	flex-direction: column;
	padding: 2rem 2rem 0 2rem;
}

.App-link {
	color: #662018;
}

h1 {
	font-size: 3em;
}

h2 {
	font-size: 2em;
	padding-bottom: 1em;
	padding-top: 1em;
}
h3 {
	font-size: 1.5em;
}

p {
	font-size: 1.5em;
	padding-bottom: 1em;
}

a {
	color: #662018;
}

a:visited {
	color: #662018;
}
ul {
	padding-left: 2em;
	padding-top: 1em;
	padding-bottom: 1em;
}

li {
	padding: 0.2em;
	font-size: 1.5em;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

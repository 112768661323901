:root {
	font-size: 62.5%;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
body {
	font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	align-items: center;
	justify-content: center;
	display: flex;
	background-color: #ebe1ce;
	color: #662018;
	font-weight: 500;
	padding: 20px;
}

code {
	font-family: "Roboto", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
